var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:".container"},[_c('Header'),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"banner"},[_c('div',[_c('p',[_vm._v("development")])]),_c('h1',[_vm._v(" Alter ")]),_c('p',[_vm._v(" Extensión de formulario para clientes potenciales en Google Ads: Búsqueda, Display, Youtube y Discovery ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"parallax"},[_c('div')])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"content"},[_c('div',[_c('p',[_vm._v("El reto")]),_c('h2',[_vm._v(" Extensión de formulario para clientes potenciales en Google Ads: Búsqueda, Display, Youtube y Discovery ")]),_c('p',[_vm._v(" Alter en Hermosillo, Sonora. Su éxito lo llevó a recibir pedidos a nivel nacional, por lo que desarrollamos una tienda en línea con un servidor que cumpla con la alta demanda de compras que recibe diariamente. ")])]),_c('div',[_c('picture',[_c('source',{attrs:{"srcset":require("../../assets/desarrollo/alter/content_img.png"),"type":"image/webp"}}),_c('img',{attrs:{"src":require("../../assets/desarrollo/alter/content_img.png"),"alt":"Imagen decorativa","loading":"lazy"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"video"},[_c('picture',[_c('img',{attrs:{"src":require("../../assets/desarrollo/alter/imagen_pc.png"),"alt":"Imagen de prueba Alter","loading":"lazy"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"imagen__mov"},[_c('picture',[_c('source',{attrs:{"srcset":require("../../assets/desarrollo/alter/imagen_mov.webp"),"type":"image/webp"}}),_c('img',{attrs:{"src":require("../../assets/desarrollo/alter/imagen_mov.png"),"alt":"Imagen de prueba alter"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"pallete"},[_c('h2',[_vm._v("Color Palette")]),_c('div',[_c('p',[_vm._v("Indigo | principal")]),_c('h3',[_vm._v("#272D69")])]),_c('div',[_c('p',[_vm._v("Apple | secundario")]),_c('h3',[_vm._v("#5CBB5B")])]),_c('div',[_c('p',[_vm._v("Davy's Grey | resalte")]),_c('h3',[_vm._v("#5C5C5C")])]),_c('div',[_c('p',[_vm._v("Bright Gray | texto")]),_c('h3',[_vm._v("#EDEDED")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"mosaico__video"},[_c('iframe',{staticClass:"only-pc",attrs:{"src":"https://www.youtube.com/embed/viGHl_weHek?si=8h2X8j_6CajGRwKC&autoplay=1&mute=1&loop=1&playlist=viGHl_weHek&showinfo=0&controls=0","title":"YouTube video player","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share","referrerpolicy":"strict-origin-when-cross-origin","allowfullscreen":""}}),_c('iframe',{staticClass:"only-mov",attrs:{"src":"https://www.youtube.com/embed/bMNzXlfcABg?si=8h2X8j_6CajGRwKC&autoplay=1&mute=1&loop=1&playlist=bMNzXlfcABg&showinfo=0&controls=0","title":"YouTube video player","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share","referrerpolicy":"strict-origin-when-cross-origin","allowfullscreen":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"mosaico"},[_c('picture',[_c('source',{attrs:{"srcset":require("../../assets/desarrollo/virgo/mosaico_1.png"),"type":"image/webp"}}),_c('img',{attrs:{"src":require("../../assets/desarrollo/virgo/mosaico_1.png"),"alt":"Prueba de alter","loading":"lazy"}})]),_c('div',{staticClass:"mosaico__stack"},[_c('picture',[_c('source',{attrs:{"srcset":require("../../assets/desarrollo/virgo/mosaico_2.png"),"type":"image/webp"}}),_c('img',{attrs:{"src":require("../../assets/desarrollo/virgo/mosaico_2.png"),"alt":"Prueba de alter","loading":"lazy"}})]),_c('picture',[_c('source',{attrs:{"srcset":require("../../assets/desarrollo/virgo/mosaico_3.png"),"type":"image/webp"}}),_c('img',{attrs:{"src":require("../../assets/desarrollo/virgo/mosaico_3.png"),"alt":"Prueba de alter","loading":"lazy"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"last__content"},[_c('h2',[_vm._v("Extensión de formulario para clientes potenciales en Google Ads: Búsqueda, Display, Youtube y Discovery ")]),_c('p',[_vm._v(" ¿Saben lo que se me hizo más difícil? Quedarme encerrada en casa, y creo que no se van a oponer, pero para el primer mes. ")])])
}]

export { render, staticRenderFns }