<template>
    <section class=".container">
        <Header />
        <main class="banner">
            <div>
                <p>development</p>
            </div>
            <h1>
                Alter
            </h1>
            <p>
                Extensión de formulario para clientes potenciales en Google Ads: Búsqueda, Display, Youtube y Discovery
            </p>
        </main>
        <section class="parallax">
            <div></div>
        </section>
        <section class="content">
            <div>
                <p>El reto</p>
                <h2>
                    Extensión de formulario para clientes potenciales en Google Ads: Búsqueda, Display, Youtube y
                    Discovery
                </h2>
                <p>
                    Alter en Hermosillo, Sonora. Su éxito lo
                    llevó a recibir pedidos a nivel nacional, por lo que desarrollamos una tienda en línea con un
                    servidor que cumpla con la alta demanda de compras que recibe diariamente.
                </p>
            </div>
            <div>
                <picture>
                    <source srcset="../../assets/desarrollo/alter/content_img.png" type="image/webp">
                    <img src="../../assets/desarrollo/alter/content_img.png" alt="Imagen decorativa" loading="lazy">
                </picture>
            </div>
        </section>
        <section class="video">
            <picture>
                <img src="../../assets/desarrollo/alter/imagen_pc.png" alt="Imagen de prueba Alter" loading="lazy">
            </picture>
        </section>
        <section class="imagen__mov">
            <picture>
                <source srcset="../../assets/desarrollo/alter/imagen_mov.webp" type="image/webp">
                <img src="../../assets/desarrollo/alter/imagen_mov.png" alt="Imagen de prueba alter">
            </picture>
        </section>
        <section class="pallete">
            <h2>Color Palette</h2>
            <div>
                <p>Indigo | principal</p>
                <h3>#272D69</h3>
            </div>
            <div>
                <p>Apple | secundario</p>
                <h3>#5CBB5B</h3>
            </div>
            <div>
                <p>Davy's Grey | resalte</p>
                <h3>#5C5C5C</h3>
            </div>
            <div>
                <p>Bright Gray | texto</p>
                <h3>#EDEDED</h3>
            </div>
        </section>
        <section class="mosaico__video">
            <iframe class="only-pc"
                src="https://www.youtube.com/embed/viGHl_weHek?si=8h2X8j_6CajGRwKC&autoplay=1&mute=1&loop=1&playlist=viGHl_weHek&showinfo=0&controls=0"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            <iframe class="only-mov"
                src="https://www.youtube.com/embed/bMNzXlfcABg?si=8h2X8j_6CajGRwKC&autoplay=1&mute=1&loop=1&playlist=bMNzXlfcABg&showinfo=0&controls=0"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </section>
        <section class="mosaico">
            <picture>
                <source srcset="../../assets/desarrollo/virgo/mosaico_1.png" type="image/webp">
                <img src="../../assets/desarrollo/virgo/mosaico_1.png" alt="Prueba de alter" loading="lazy">
            </picture>
            <div class="mosaico__stack">
                <picture>
                    <source srcset="../../assets/desarrollo/virgo/mosaico_2.png" type="image/webp">
                    <img src="../../assets/desarrollo/virgo/mosaico_2.png" alt="Prueba de alter" loading="lazy">
                </picture>
                <picture>
                    <source srcset="../../assets/desarrollo/virgo/mosaico_3.png" type="image/webp">
                    <img src="../../assets/desarrollo/virgo/mosaico_3.png" alt="Prueba de alter" loading="lazy">
                </picture>
            </div>
        </section>
        <section class="last__content">
            <h2>Extensión de formulario para clientes potenciales en Google Ads: Búsqueda, Display, Youtube y Discovery
            </h2>
            <p>
                ¿Saben lo que se me hizo más difícil? Quedarme encerrada en casa, y creo que no se van a oponer, pero
                para el primer mes.
            </p>
        </section>
        <Footer />
    </section>
</template>
<script>
import Footer from '../../components/on-site/Footer.vue';
import Header from '../../components/on-site/Header.vue';

export default {
    components: { Footer, Header },

    mounted() {
        window.addEventListener('scroll', function () {
            if (this.window.innerWidth >= 768) return ''

            const { height } = this.document.querySelector('.banner').getBoundingClientRect();
            const heightParallax = this.document.querySelector('.parallax').getBoundingClientRect().height;
            const parallaxPicture = document.querySelector('.parallax>div');
            const scrollPositionY = window.scrollY;

            // Define el rango de la animación según sea necesario
            const minRangeY = height; // Ajusta según sea necesario
            const maxRangeY = heightParallax; // Ajusta según sea necesario
            const tope = 100;

            // Calcula el porcentaje de desplazamiento dentro del rango
            const percentageY = (maxRangeY - minRangeY) / tope

            const factor = (93 / (1 + 173.39 * Math.E ** (-0.0062 * this.window.innerHeight)));

            const cantidadScroll = percentageY * (scrollPositionY - minRangeY) / factor
            // console.log(cantidadScroll)

            if (scrollPositionY > minRangeY || scrollPositionY <= maxRangeY) {
                parallaxPicture.style.setProperty('--traslado', `${cantidadScroll}%`);
            }

            // && height < scrollPositionY - minRangeY
            if ((scrollPositionY >= maxRangeY && heightParallax < scrollPositionY - minRangeY) || cantidadScroll >= tope) {
                parallaxPicture.style.setProperty('--traslado', `${tope}%`);
            }

            if (scrollPositionY < minRangeY) {
                parallaxPicture.style.setProperty('--traslado', `0%`);
            }
        })
    },
}
</script>

<style scoped>
*,
*::before,
*::after {
    box-sizing: border-box;
}

h1,
h2,
h3,
p {
    margin: 0;
}

picture {
    display: flex;
    width: fit-content;
    height: fit-content;
}

.container {
    display: grid;
}

.banner {
    background-size: cover;

    background-image: url("../../assets/desarrollo/alter/banner_mov.png");
    background-image: url("../../assets/desarrollo/alter/banner_mov.webp");
    padding: 33.091787439613526VW 22.22222222222222VW 149.7584541062802VW 9.66183574879227VW;
}

.banner div {
    background-color: #3702FB;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 21.014492753623188VW;
    height: 4.830917874396135VW;
}

.banner div p {
    color: #FFF;
    font-family: Gramatika-Bold;
    font-style: normal;
    font-weight: 400;

    font-size: 1.932367149758454VW;
    line-height: 135.8%;
}

h1 {
    color: #FFF;
    font-family: Gramatika-Medium;
    font-style: normal;
    font-weight: 400;

    font-size: 19.32367149758454VW;
    line-height: 121.3%;
    margin: 2.657004830917874VW 0 2.898550724637681VW;
}

.banner>p {
    color: #FFF;
    font-family: "IBM Plex Mono";
    font-style: normal;
    font-weight: 400;

    font-size: 2.657004830917874VW;
    line-height: 149.5%;
}

.parallax {
    height: 190lvh;
    margin: 0;
}

.parallax>div {
    --traslado: 0%;
    width: 100%;
    height: 100lvh;
    background-image: url("../../assets/desarrollo/alter/parallax.png");
    background-image: url("../../assets/desarrollo/alter/parallax.webp");
    background-size: auto 100lvh;
    background-repeat: no-repeat;
    background-position-x: var(--traslado);

    position: sticky;
    top: 0;
}

.content {
    padding: 12.318840579710146VW 5.797101449275362VW;
}

.content>div:first-of-type {
    padding: 0 0 0 6.763285024154589VW;
}

.content p:first-of-type {
    color: #EF67C2;
    font-family: "IBM Plex Mono";
    font-style: normal;
    font-weight: 400;

    font-size: 2.657004830917874VW;
    line-height: 125%;
    margin: 0 0 8.937198067632849VW;
}

.content h2 {
    color: #000;
    font-family: Gramatika-Bold;
    font-style: normal;
    font-weight: 400;

    font-size: 5.314009661835748VW;
    line-height: 125%;
    padding: 0 10.046377VW 7.246376811594203VW 0;
}

.content p:last-of-type {
    color: #231F20;
    font-family: "IBM Plex Mono";
    font-style: normal;
    font-weight: 400;

    font-size: 2.657004830917874VW;
    line-height: 190.5%;
    padding: 0 7.246376811594203VW 10.38647342995169VW 0;
}

.content img {
    width: 88.64734299516908VW;
    aspect-ratio: 367/245;
}

.video {
    display: none;
    width: 100%;
    height: 100lvh;
}

.imagen__mov picture {
    width: 100%;
    height: fit-content;
}

.imagen__mov img {
    width: 100%;
    height: 100lvh;
    object-fit: cover;
}

.pallete {
    display: grid;
}

.pallete h2 {
    color: #000;
    font-family: Gramatika-Bold;
    font-style: normal;
    font-weight: 400;
    text-align: center;

    font-size: 5.314009661835748VW;
    line-height: 125%;
    margin: 21.980676328502415VW 0 20.048309178743963VW;
}

.pallete div {
    height: 45.65217391304348VW;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.2077294685990339VW 0;
}

.pallete h3 {
    color: #FFF;
    font-family: Gramatika-Bold;
    font-style: normal;
    font-weight: 400;

    font-size: 8.454106280193237VW;
    line-height: 125%;
}

.pallete p {
    color: #FFF;
    text-align: center;
    font-family: "IBM Plex Mono";
    font-style: normal;
    font-weight: 400;

    font-size: 4.3478260869565215VW;
    line-height: 125%;
}

.pallete div:nth-of-type(4) h3,
.pallete div:nth-of-type(4) p {
    color: #231F20;
}

.pallete p {
    order: 2;
}

.pallete div:nth-of-type(1) {
    background-color: #272D69;
}

.pallete div:nth-of-type(2) {
    background-color: #5CBB5B;
}

.pallete div:nth-of-type(3) {
    background-color: #5C5C5C;
}

.pallete div:nth-of-type(4) {
    background-color: #EDEDED;
}

.mosaico__video {
    width: 83.57487922705315VW;
    height: 193.23671497584542VW;
    margin: 21.980676328502415VW auto 0;
    overflow: hidden;
    pointer-events: none;
}

.mosaico__video img {
    width: 83.81642512077295VW;
    aspect-ratio: 1690/859;
}

.mosaico {
    display: none;
}

.mosaico>picture {
    margin: 0 auto;
}

.mosaico>picture img {
    width: 83.81642512077295VW;
    aspect-ratio: 347/346;
}

.mosaico div {
    display: grid;
    grid-template-areas: "stack";
}

.mosaico div>* {
    grid-area: stack;
}

.mosaico div picture:first-of-type {
    margin: 0 auto;
}

.mosaico div {
    width: 83.81642512077295VW;
    margin: 0 auto;
}

.mosaico div picture:first-of-type img {
    width: 83.81642512077295VW;
    aspect-ratio: 347/260;
}

.mosaico div picture:last-of-type {
    margin: -13.285024154589372VW 0 0 31.40096618357488VW;
}

.mosaico div picture:last-of-type img {
    width: 48.06763285024155VW;
    aspect-ratio: 199/316;
}

.last__content {
    padding: 21.980676328502415VW 9.903381642512077VW;
}

.last__content h2 {
    color: #231F20;
    text-align: center;
    font-family: Gramatika-Bold;
    font-style: normal;
    font-weight: 400;

    font-size: 4.830917874396135VW;
    line-height: 125%;
}

.last__content p {
    color: #231F20;
    text-align: center;
    font-family: "IBM Plex Mono";
    font-style: normal;
    font-weight: 400;

    font-size: 2.657004830917874VW;
    line-height: 190.5%;
    padding: 10.869565217391305VW 3.6231884057971016VW 0;
}

.only-pc {
    display: none;
}

.mosaico__video iframe {
    width: 100%;
    height: 100%;
    transform: scale(1.1);
}

@media (min-width: 768px) {

    .only-pc {
        display: block;
    }

    .only-mov {
        display: none !important;
    }

    .banner {
        background-image: url("../../assets/desarrollo/alter/banner_pc.png");
        background-image: url("../../assets/desarrollo/alter/banner_pc.webp");
        padding: 20.885416666666668VW 15.46875VW 18.385416666666668VW 63.072916666666664VW;
    }

    .banner div {
        width: 5.9375VW;
        height: 1.3541666666666667VW;
    }

    .banner div p {
        font-size: 0.625VW;
        line-height: 135.8%;
    }

    h1 {
        font-size: 6.770833333333333VW;
        line-height: 121.3%;
        margin: 1.5625VW 0;
    }

    .banner>p {
        font-size: 0.7291666666666666VW;
        line-height: 149.5%;
    }

    .parallax {
        height: fit-content;
        margin: 7.8125VW auto 11.197916666666668VW;
    }

    .parallax>div {
        --traslado: 0;
        width: 83.33333333333334VW;
        height: 37.34375VW;
        background-size: cover;
        animation: none;
        position: static;
        margin: 0 auto;
    }

    .content {
        padding: 0 10.104166666666666VW 15.46875VW 10.46875VW;
        display: flex;
        column-gap: 4.166666666666666VW;
    }

    .content p:first-of-type {
        font-size: 1.0416666666666665VW;
        line-height: 125%;
        margin: 1.4583333333333333VW 0 0;
    }

    .content h2 {
        font-size: 1.8229166666666667VW;
        line-height: 125%;
        padding: 0;
        margin: 2.604166666666667VW 0 5.729166666666666VW;
    }

    .content p:last-of-type {
        width: 32.96875VW;
        font-size: 0.8333333333333334VW;
        line-height: 190.5%;
        padding: 0;
    }

    .content img {
        width: 34.21875VW;
        aspect-ratio: 657/439;
    }

    .video {
        display: block;
        width: 83.33333333333334VW;
        height: 83.33333333333334VW;
        margin: 0 auto;
    }

    .video img {
        width: 83.33333333333334VW;
        aspect-ratio: 1;
    }

    .imagen__mov {
        display: none;
    }

    .pallete {
        grid-template-columns: repeat(4, 1fr);
    }

    .pallete h2 {
        font-size: 1.1458333333333333VW;
        line-height: 125%;
        grid-column: 1 / -1;
        margin: 4.739583333333333VW 0 4.322916666666667VW;
    }

    .pallete div {
        height: 44.895833333333336VW;
        gap: 0.26041666666666663VW 0;
        padding: 0 0 12.760416666666666VW;
        justify-content: center;
        align-items: end;
        flex-direction: row;
    }

    .pallete h3 {
        font-size: 1.8229166666666667VW;
        line-height: 125%;
        order: 2;
        transform: rotate(-180deg);
        writing-mode: vertical-lr;
    }

    .pallete p {
        font-size: 0.9375VW;
        line-height: 125%;
        transform: rotate(-180deg);
        writing-mode: vertical-lr;
    }

    .pallete p {
        order: 1;
    }

    .mosaico__video {
        width: 88.02083333333334VW;
        height: 44.739583333333336VW;
        margin: 21.980676328502415VW auto 0;
    }

    .mosaico__video img {
        width: 88.02083333333334VW;
        background-color: magenta;
    }


    .mosaico {
        width: 88.02083333333334VW;
        margin: 0 auto;
    }

    .mosaico>picture {
        margin: 0;
    }

    .mosaico>picture img {
        width: 43.75VW;
        aspect-ratio: 840/762;
    }

    .mosaico div picture:first-of-type {
        margin: 0;
    }

    .mosaico div {
        width: 44.27083333333333VW;
        margin: 0;
    }

    .mosaico div picture:first-of-type img {
        width: 44.27083333333333VW;
        aspect-ratio: 850/762;
    }

    .mosaico div picture:last-of-type {
        margin: 2.4479166666666665VW 0 0 13.489583333333332VW;
    }

    .mosaico div picture:last-of-type img {
        width: 23.229166666666668VW;
    }

    .last__content {
        padding: 11.302083333333334VW 31.145833333333332VW;
    }

    .last__content h2 {
        font-size: 1.3020833333333335VW;
        line-height: 125%;
    }

    .last__content p {
        font-size: 0.8333333333333334VW;
        line-height: 190.5%;
        padding: 3.3854166666666665VW 1.4583333333333333VW 0;
    }

    .mosaico__video iframe {
        transform: scale(1.2);
    }


}
</style>